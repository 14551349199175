/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  }
  
  a {
    color: #FE2202;
    text-decoration: none;
  }
  
  a:hover {
    color: #717ff5;
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Nunito", sans-serif;
  }
  
  /*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-header {
    text-align: center;
    padding-bottom: 40px;
  }
  
  .section-header h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: #FE2202;
    text-transform: uppercase;
  }
  
  .section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: #012970;
  }
  
  @media (max-width: 768px) {
    .section-header p {
      font-size: 28px;
      line-height: 32px;
    }
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background: #012970;
    min-height: 40px;
    margin-top: 82px;
    color: #fff;
  }
  
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 57px;
    }
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 500;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
  }
  
  .breadcrumbs ol a {
    color: #fff;
    transition: 0.3s;
  }
  
  .breadcrumbs ol a:hover {
    text-decoration: underline;
  }
  
  .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #8894f6;
    content: "/";
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: #FE2202;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #6776f4;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  .header {
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
  }
  
  .header.header-scrolled {
    background: #fff;
    padding: 15px 0;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  }
  
  .header .logo {
    line-height: 0;
  }
  
  .header .logo img {
    max-height: 40px;
    margin-right: 6px;
  }
  
  .header .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #012970;
    font-family: "Nunito", sans-serif;
    margin-top: 3px;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #001846;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #FE2202;
  }
  
  .navbar .getstarted,
  .navbar .getstarted:focus {
    background: #FE2202;
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 4px;
    color: #fff;
  }
  
  .navbar .getstarted:hover,
  .navbar .getstarted:focus:hover {
    color: #fff;
    background: #5969f3;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: #FE2202;
  }
  
  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .megamenu {
    position: static;
  }
  
  .navbar .megamenu ul {
    margin-top: 5px;
    right: 0;
    padding: 10px;
    display: flex;
  }
  
  .navbar .megamenu ul li {
    flex: 1;
  }
  
  .navbar .megamenu ul li a,
  .navbar .megamenu ul li:hover>a {
    color: #013289;
  }
  
  .navbar .megamenu ul li a:hover,
  .navbar .megamenu ul li .active,
  .navbar .megamenu ul li .active:hover {
    color: #FE2202;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #012970;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 1200px) {
    .mobile-nav-toggle {
      display: block;
    }
   
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(1, 22, 61, 0.9);
    transition: 0.3s;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff !important;
    overflow-y: auto;
    transition: 0.3s;
  }


  
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #012970;
  }
  
  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #FE2202;
  }
  
  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #FE2202;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }
  
  /*--------------------------------------------------------------
  # Hero
  --------------------------------------------------------------*/
  .Hero {
    margin-top: 20px;
    padding-bottom: 0;
  }
  
  .Hero .btn-buy{
    margin-top: 30px;
    display: inline-block;
    padding: 8px 40px 10px 40px;
    border-radius: 50px;
    background-color: #FE2202;
    color: #FFF;
    transition: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    border: 1px solid #FE2202;
  }
  
  .Hero .btn-buy:hover{
    background: #fff;
    color: #FE2202;
  }
  
  .Hero .hero-item {
    box-sizing: content-box;
    margin: 40px 30px;
    box-shadow: 0px 0 20px rgba(1, 41, 112, 0.1);
    background: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: 0.3s;
  }
  
  .Hero .hero-item .stars img {
    width: 100%;
  }
  
  .Hero .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .Hero .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #FE2202;
  }
  
  .Hero .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #FE2202;
  }
  
  .Hero .swiper-slide {
    opacity: 0.3;
  }
  .text_center{
      text-align: center;
  }
  @media (max-width: 1199px) {
    .Hero .swiper-slide-active {
      opacity: 1;
    }
  
    .Hero .swiper-pagination {
      margin-top: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .Hero .swiper-slide-next {
      opacity: 1;
      transform: scale(1.12);
    }
  }
  
  /*--------------------------------------------------------------
  # Index Page
  --------------------------------------------------------------*/
  
  /*--------------------------------------------------------------
  # delivery
  --------------------------------------------------------------*/
  .delivery {
    padding: 70px 0 60px;
  }
  
  .delivery .delivery-box {
    display: flex;
    align-items: center;
    padding: 30px;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  }
  
  .delivery .delivery-box i {
    font-size: 42px;
    line-height: 0;
    margin-right: 20px;
    color: #FE2202;
  }
  
  .delivery .delivery-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #0b198f;
  }
  
  .delivery .delivery-box p {
    padding: 0;
    margin: 0;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
  }
  
  
  /*--------------------------------------------------------------
  # payment
  --------------------------------------------------------------*/
  .payment .box {
    width: 100%;
    padding: 40px 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
  }
  
  .payment .box:hover {
    transform: scale(1.1);
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  }
  
  .payment h3 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .payment .payments {
    font-size: 36px;
    color: #444444;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
  }
  
  .payment .btn-buy{
    margin-top: 30px;
    display: inline-block;
    padding: 8px 40px 10px 40px;
    border-radius: 50px;
    color: #FE2202;
    transition: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    border: 1px solid #FE2202;
  }
  
  .payment .btn-buy:hover{
    background: #FE2202;
    color: #fff;
  }
  .payment .btn-buydisabled{
    margin-top: 30px;
    display: inline-block;
    padding: 8px 40px 10px 40px;
    border-radius: 50px;
    color: gray;
    transition: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    border: 1px solid gray;
  }
  
  .payment .btn-buydisabled:hover{
    background: gray;
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # F.A.Q
  --------------------------------------------------------------*/
  .faq .accordion-collapse {
    border: 0;
  }
  
  .faq .accordion-button {
    padding: 15px 15px 20px 0;
    font-weight: 600;
    border: 0;
    font-size: 18px;
    color: #444444;
    text-align: left;
  }
  
  .faq .accordion-button:focus {
    box-shadow: none;
  }
  
  .faq .accordion-button:not(.collapsed) {
    background: none;
    color: #FE2202;
    border-bottom: 0;
  }
  
  .faq .accordion-body {
    padding: 0 0 25px 0;
    border: 0;
  }

  /* Remove border from the accordion header */
.accordionHead {
    border:none !important;
}

/* Remove border from the accordion body */
.accordionBody {
    border: none !important;
}

/* If the accordion item itself has a border, remove it */
.accordionItem {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}


  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  .footer {
    background: #f6f9ff;
    padding: 0 0 30px 0;
    font-size: 14px;
  }
  
  
  .footer .footer-top {
    background-size: contain;
    border-top: 1px solid #e1ecff;
    border-bottom: 1px solid #e1ecff;
    padding: 60px 0 30px 0;
  }
  
  @media (max-width: 992px) {
    .footer .footer-top {
      background-position: center bottom;
    }
  }
  
  .footer .footer-top .footer-info {
    margin-bottom: 30px;
  }
  
  .footer .footer-top .footer-info .logo {
    line-height: 0;
    margin-bottom: 15px;
  }
  
  .footer .footer-top .footer-info .logo img {
    max-height: 40px;
    margin-right: 6px;
  }
  
  .footer .footer-top .footer-info .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #012970;
    font-family: "Nunito", sans-serif;
    margin-top: 3px;
  }
  
  .footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Nunito", sans-serif;
  }
  
  .footer .footer-top .social-links a {
    font-size: 20px;
    display: inline-block;
    color: rgba(1, 41, 112, 0.5);
    line-height: 0;
    margin-right: 10px;
    transition: 0.3s;
  }
  
  .footer .footer-top .social-links a:hover {
    color: #012970;
  }
  
  .footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #012970;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
  }
  
  .footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  .footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #d0d4fc;
    font-size: 12px;
    line-height: 0;
  }
  
  .footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  .footer .footer-top .footer-links ul a {
    color: #013289;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  .footer .footer-top .footer-links ul a:hover {
    color: #FE2202;
  }
  
  .footer .footer-top .footer-contact p {
    line-height: 26px;
  }
  
  .footer .copyright {
    text-align: center;
    padding-top: 30px;
    color: #012970;
  }
  
  .footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #012970;
  }


  .navbar-mobile .navbar ul  {
    height: 300px !important;
}

.dropdown-content {
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}
