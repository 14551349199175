
body {
  margin: 0;
  padding: 0;
  /* display: flex;
  flex-direction: column;
  min-height: 100vh ; */
}

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: inherit !important;
}

#content {
  flex-grow: 1;
}
.payment_container{
  display: flex;
  flex-direction: column;
  min-height: 100vh ; 
}

/* navbar */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 0 !important;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.navbar-dropdown {
  /* position: relative;
  z-index: 9999;  */
  /* background-color: #001846; */
}

.navbar-logo img {
  height: 50px;
}
.navbar-left{
  display: flex;
}
.navbar-links {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-link {
  margin-left: 20px;
  margin-right: 20px;
  padding: 25px !important;
  font-size: 18px;
  font-weight: 500;
  color: #555;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.navbar-link.active {
  border-bottom: 4px solid #012652;
  color: #333;
}

.navbar-link:hover {
  color: #333;
}

.navbar-dropdown {
  position: relative;
}

.navbar-dropdown-toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: none;
  border: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
}
.profile-icon svg{
  
  width: 2rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
 .dropdown-icon svg {
   fill: white;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-text {
  color: white;
}


.navbar-dropdown-toggle:hover {
  color: #333;
}

.navbar-dropdown-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  right: 0;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  list-style: none;
  width: 200px;
}

.navbar-dropdown-item  {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: #555;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.navbar-dropdown-item-Link{
  display: block;
  padding: 10px;
  text-decoration: none !important;
  color: #012652;
}

.navbar-dropdown-item:hover {
  color: #fff !important;
  background-color: #012652 !important;
  transition: all 0.3s ease-in-out;
}

.navbar-dropdown-item-Link:hover {
  color: #fff !important;
}
.m45 {
  margin: 45px !important;
}
@media (max-width: 767px) { 
  .dropdown-menu-mobile {
    width: 100vw;
    position: fixed;
    left: 0;
    top: 230px; 
  }
  .nav-link{
    background-color: none !important;
    color: #001846 !important;
   
  }  

  .dropdownMobile{
    background-color: #001846 !important;
    color: White !important;
  }
  .profile-text{
    color: White !important;
    margin-left: -7px !important;
  }
}

/* //navbar end */
.box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255,255,255,0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #06D85F;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.button:hover {
  background: #06D85F;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
  z-index: 11;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}
.popup {
  margin: 15rem auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  height: 60%;
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 1234;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06D85F;
}
.popup .content {
 height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (max-width: 700px) {
  .m_1responsive700 {
    margin: 8px;
  }

  .m_12responsive700 {
    margin: 12px;
  }

  .m45 {
    margin: 0px !important;
  }
  
  

.jb{
  justify-content: space-between;
}
  /* Adjusting the input fields for responsiveness */
  input[type="text"] {
    /* width: 100%; */
    max-width: 80%;
    box-sizing: border-box; /* Ensures padding doesn't make the input larger than its parent */
  }
  .Quantity input[type="number"] {
    max-width: 100%;
    box-sizing: border-box; 
  }
  .rcp_info input[type="text"] {
    max-width: 100%;
 
    box-sizing: border-box; /* Ensures padding doesn't make the input larger than its parent */
  } 
  select.form-control {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box; /* Ensures padding doesn't make the input larger than its parent */
  }

  .uploadFilePlaceholder {
    font-size: 9px !important;
  }


  /* Increase width for cards for better visibility on smaller screens */
  .card {
    width: 98%;
  margin: 2px;
  }

  /* Adjusting the popup for responsiveness */
  .popup {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.text_brandColor{
  color: #012652 !important;
}


@media screen and (min-width:360px   ) and  (max-width:660px){ 
  .bg_gray{
    background-color: #555 !important;
    height: 100vh;
  }
  .dropdownMobile{
    z-index: 111;
    background-color: #f8f9fa;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    color: #012652 !important;
  
  }
  .profile-text {
    /* color: black !important; */
    font-weight: bold;
    /* padding: 5px; */
  }
 .navbar-nav{
   width: 100%;
   margin: auto;
 }
 .order-header{
   display: none !important;
 }
  .dropdown-icon svg {
  fill: rgb(255, 255, 255);
   width: 1rem;
   height: 1rem;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 /* .navbar-dropdown-toggle  {
  color: white !important;
}  */
.navbar-toggler {
background-color: white !important;
}
  .dropdownButtonMobile{
  margin-left: 0 !important;
   
  
  }
  
}
.btn_groupFlex{
  display: flex;
  justify-content:center;
  align-items: center;
}
.loginBtn{
  text-align: left;
  margin-bottom: 10px;
}
.icon{
  width: 1rem;
  margin-right: 6px;
}

.orderSubmit_btn{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  background-color: #001846 !important;
  color: white;
  border: 3px #001846 solid !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 22px !important;
  margin-top: 10px;
  padding: 5px 15px !important;
}

.orderSubmit_btn:hover {
  border: 3px #001846 solid !important;
  background-color: #fff !important;
  color: #001846 !important;
}

.ordercancel_btn{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  margin-left: 25px;
  background-color: #fff !important;
  color: #001846 !important;
  border: 3px #001846 solid !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 22px !important;
  margin-top: 10px;
  padding: 5px 15px !important;
 
}

.ordercancel_btn:hover {
  border: 3px #001846 solid !important;
  background-color: #001846 !important;
  color: #fff !important;
}

.googleBtn{
  display: flex;
  align-items: center;
  color: #124;
  border: none;
  background-color: rgb(227, 224, 224);
}

.hide {
  display: none;
}
.align_center{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex{
  display: flex;
  justify-content: space-between;
}
.flex_justify_around{
  display: flex;
  justify-content: space-around;
}

/* terms Condition */
* {
  box-sizing: border-box;
}



#MessageViewBody a {
  color: inherit;
  text-decoration: none;
}

p {
  line-height: inherit
}

.desktop_hide,
.desktop_hide table {
  mso-hide: all;
  display: none;
  max-height: 0px;
  overflow: hidden;
}

.image_block img+div {
  display: none;
}

/* footer starts */
/* footer */
.footer_div{  
 
  margin-top:auto;



}
.footer_container{
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
 
}

.fotter3rd_Layer{
background-color: #001846;
padding: 25px 0px;
width: 100%;
}
.footer_Section_Layer{
  margin-top: 15px;
  max-width: 1450px !important;
  margin-left: auto;
  margin-right: auto;
}
.elementDiv1{
  padding: 0px 15px;
}
.footerIcon{
  text-align: left;
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
}
.footerImg_container{
  padding: 0 0 10px;
 
}
.footerImg_container img{
 width: 50%;
 
}
.footer1stElement{
  display: block;
  align-items: center;
  width: 100%;
}
.firstElementAlign{
display: flex;
align-items: center;

}
.firstElementAlign_text{
  margin-left: 12px;

}
.w35px{
  width: 35px;
}
.socialMedia_icon_size{
  width: 30px;
}
.margin_bottom12{
  margin-bottom: 12px;
}
.copyRight_Style{
  margin: auto;
  color: #f5f5f5;
  text-align: center;
}
.col_30{
  width: 30%;
}
.col_25{
  width: 25%;
}
.col_15{
  width: 15%;
}
.m_left25P{
  margin-left: 25%;
  }
  .m_L15P{
    margin-left: 15%;
  }

  .viewOrderPaymentStatus{
    margin-left:25px
  }
@media only screen and (max-width: 600px) {
  .block{
    display:block
  }
  .col_100{
    width: 100%;
  }
  .margin_left20{
    margin-left: 20px;
  }
  .copyRight_Style_For_mobile{
    font-size: 12px;
  }
  .m_0{
    margin: 0px;
  }
}
/* footer end  */

@media (max-width:655px) {

  .desktop_hide table.icons-inner,
  .social_block.desktop_hide .social-table {
    display: inline-block !important;
  }

  .icons-inner {
    text-align: center;
  }

  .icons-inner td {
    margin: 0 auto;
  }

  .row-content {
    width: 100% !important;
  }

  .mobile_hide {
    display: none;
  }

  .stack .column {
    width: 100%;
    display: block;
  }

  .mobile_hide {
    min-height: 0;
    max-height: 0;
    max-width: 0;
    overflow: hidden;
    font-size: 0px;
  }

  .desktop_hide,
  .desktop_hide table {
    display: table !important;
    max-height: none !important;
  }

  .reverse {
    display: table;
    width: 100%;
  }

  .reverse .column.first {
    display: table-footer-group !important;
  }

  .reverse .column.last {
    display: table-header-group !important;
  }

  .row-13 td.column.first .border,
  .row-13 td.column.last .border,
  .row-17 td.column.first .border,
  .row-17 td.column.last .border,
  .row-21 td.column.first .border,
  .row-21 td.column.last .border,
  .row-27 td.column.first .border,
  .row-27 td.column.last .border,
  .row-31 td.column.first .border,
  .row-31 td.column.last .border,
  .row-37 td.column.first .border,
  .row-37 td.column.last .border,
  .row-41 td.column.first .border,
  .row-41 td.column.last .border,
  .row-47 td.column.first .border,
  .row-47 td.column.last .border,
  .row-51 td.column.first .border,
  .row-51 td.column.last .border,
  .row-57 td.column.first .border,
  .row-57 td.column.last .border,
  .row-61 td.column.first .border,
  .row-61 td.column.last .border,
  .row-7 td.column.first .border,
  .row-7 td.column.last .border {
    padding: 0;
    border-top: 0;
    border-right: 0px;
    border-bottom: 0;
    border-left: 0;
  }
}

.printSizeDemo img{
width: auto;

}
.printSizeDemoCol{
position: relative;
  border-top: 3px solid #012652;
  border-right: 3px solid #012652;
  border-left: 3px solid #012652;
  border-radius: 20px 20px 0px 0px;
}
.printSizeDemoCol img{
  border-radius: 20px 20px 0px 0px;
}

.printSizeText{
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 25px;
  padding: 10px;

}
.printSizeTextDiv{
  width: 100%;
  background-color: #012652;
  border-radius: 0px 0px 20px 20px;
/* position:absolute;
top: 93%;
left: 50%;
transform: translate(-50%, -50%); */

}
.mt-25{
  margin-top:25px ;
}

/* //custom alert box */

.alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
 
}

.alert-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #012652;
 
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  z-index: 1000;
  text-align: center;
 
}

.alert-box h2 {
  color: white;
  margin-top: 1rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
}
.alert-box img{
  height: 50px;
  width: 50px;
}
.alert-box .cancelImg{
  height: 25px;
  width: 25px;
 margin-left: 92%;
 top: 0;
}



.test{
  color: #06D85F ;
}
.j-e{
  display: flex !important;
  justify-content: space-between !important;
}
.cursor_pointer{
  cursor: pointer;
  z-index: 1111;
}
.forgetPassStyle{
margin-top: 25px ;
cursor: pointer;
}
@media screen and (max-width: 768px) {
  /* .navbar-nav {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .navbar-nav .nav-link {
  
    color: black !important;
    background-color: #f8f9fa;
    padding-left: 10px !important;
   
  } */
 
}
@media only screen and (max-width: 991.98px) {
  .my-2.my-lg-0 {
    width: 100% !important;
  }
}
.info_icon{
  width:"30px";
  height:"30px"
}

.costOrder_Style{
display: flex;
justify-content: space-between;
align-items: center;
}
.addButtonStyle{
  width: 50px !important;
  font-weight:700 !important;
  font-size: 25px !important;
  line-height: 1 !important;
  border-radius:5px !important;
  color: white !important;
  border: none !important;
  background-color: rgb(17, 34, 68) !important;
}
.edit_shop_image_div {

  min-width: 64px;
 height: 64px;

  display:flex;
  margin: auto;

}

.tab-link{
  padding: 5px;
  text-decoration: none !important;
  color: #012652 !important;
  font-size: 20px;
  font-weight: 700;
}
.resetPassSubmitBtnCOlor{
  background-color: #dcd5d5 !important;
  color: #012652 !important;
}


.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.brand_color{
  background-color: #001846 !important;
}

/* ///text editor  styles*/

body {
  background: #f3f1f2;
  font-family: sans-serif;
}

/* #root {
  margin: 1rem 4rem;
} */

#root .ql-container {
  width: 100%;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
#root .ql-snow.ql-toolbar {
  display: block;
  width: 100%;
  height: 3rem;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

#root .ql-editor {
  min-height: 20em;
  display: block;
}

.hr_lineStyle{
margin: 5px 0px 15px 0 !important;
color: #bababa !important;

}

 /* popup  */

 .alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: rgba(244, 238, 238, 0.5); */
  z-index: 999;
}

.alert-box {
  position: fixed;
  top: 50%;
  left: 50%;
 
  transform: translate(-50%, -50%);
  /* background-color: #f5f5f5; */
  background-color: #012652;
  padding: 1rem 20px 40px 40px;
 
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(116, 112, 112, 0.3);
  z-index: 1000;
  text-align: center;
 
}

.alert-box h2 {
  color: white;
  margin-top: 1rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
}


.ticket-alert-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:rgb(11, 8, 98);
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(116, 112, 112, 0.3);
  z-index: 1000;
  text-align: center;
 
}

.ticket-alert-box h2 {
  color: white;
  margin-top: 1rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
}
.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  position: relative !important;
  display: inline-block;
  width: 100% !important;
}

.notification-badge {
  width: 25px;
  height: 25px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 12px;
  position: absolute;
  font-weight: 700;
  top: 0px;
  right: -10px;
}
.notification-badge-individual {
  background-color: red;
  margin-top: 50px;
  margin-left: 20px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 700;
}


@media only screen and (max-width: 600px) {
      .phone_flex_center{
        justify-content: center;
      }  
  /* .d-none{
    display: block;
  } */
  .block_phone{
    display: block !important;
  }

.all-content{
margin: 20px !important;
}
  .diplay_none{
    display: block !important;
  }

  .d-none-phone{
    display: none !important ;
  }
  .text_Align_Left{
text-align: left !important;
  }

  .tab{
    font-size: 14px;
    padding:8px 11px
  }
  .admin-dis-chat{
    padding: 10px !important;
  }
  .tab-content{
    padding:20px 10px !important;
  }
  .btn_phone{
    padding: 8px 10px !important;
  }

  .p-4{
    padding:0rem !important;
  }
  .px-4{
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
.font_16{
font-size: 16px !important ;
}
.weight_600{
font-weight: 600;
}
.font_16{
font-size: 16px !important;
}

}

.diplay_none{
  display: none !important;
}

.whiteColor{
  color: white !important;
}
/* src/RoundSlider.css */
/* home slider starts */
.carousel-container {
  width: 93%;
  height: 400px;
  position: relative;
  overflow: hidden;
}

.carousel-wrapper {
  display: flex;
  transition: transform 1s;
}

.caro-item {
  flex-shrink: 0;
  width: 400px;
  height: 400px;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  transform: scale(0.8);
  transition: transform 1s;
  margin-right: -80px; 
  z-index: 1;
}

.caro-item.active {
  transform: scale(1);
  z-index: 2;
}
.term_conditoinPg{
  .tm_td1 {
    text-align: center;width: 100%
  }
  .tm_td1 h1{
    margin: 0;
    text-align: center;
    color: white;
  }
  .tm_td1 {
    
  }
}
/* Mobile styles */
@media only screen and (max-width: 768px) {
  .carousel-container {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
  }
  .caro-item {
    width: 250px;  /* Smaller width */
    height: 250px; /* Corresponding height */
    margin-right: -50px; /* Adjust overlapping */
  }
}



/* home slider end  */

.baseCard{
  box-shadow: 0px 0px 5px 5px rgb(240, 240, 240);

}

/* new order flip cards style  */
.card_newOrder {
  width: auto;
  height: 400px;
  perspective: 1000px; /* This gives a nice 3D effect on flip */
  overflow: hidden;

}

.card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s; /* Adjust duration as needed */
}

.card_newOrder:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front{
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden; /* Hide back of pane during flip */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-front {
  z-index: 2; /* Displayed on top by default */
}


/* Style the image to fit the card */
.card-front img {
  width: 100%;
  height: 100%;
  object-fit: fill; /* This will prevent the image from getting stretched */
}

.card-back {
  transform: rotateY(180deg);
  position: relative; /* Added to position the .blurred-bg div */
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  /* Removed the background-image and filter properties from here */
}

.blurred-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Place it behind the content */
  background-image: url('https://i.ibb.co/Yf27Dcv/Round-Neck-White.jpg'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  filter: blur(5px);
}

/* Rest of the .card-back styles remain the same as before */


.card-back h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center; /* Center the title */
}

.card-back ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  position: relative;
}

.card-back li {
  margin-bottom: 10px;
  font-size: 18px; /* Adjust the size as needed */
}

.card-back li span {
  font-weight: bold; /* Make the label bold */
  margin-right: 5px; /* Space between label and content */
}

.card-back .highlight {
  font-weight: bold;
}
#copyButton1, #copyButton2, #copyButton3, #copyButton4, #copyButton5, #copyButton6 {
  position: absolute;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 18px;
  width: 100%;
  height: 100%;
  /* Add additional styles as needed */
}
.headerName{
  margin-left: 25px;
  
}
/* Size Table */
.size_table{
  margin: auto;
  width: 95%;
}

.tg-0lax_title{
  font-weight: bolder;
  font-size: 20px;
  background-color: #d8d8d8;
}

.tg-0lax{
  border: 2px black solid;
  padding: 15px 25px;
}
.paymentStatus{
  margin-top: 70px;
}
.textCenter_verticAndHorizontal{
  text-align: center;
  display: flex;
   align-items: center; 
   justify-content: center;
   height: 100%
}

@media (max-width: 600px) {
 
  .cardImg{
/* max-height: 300px !important; */
  }
.card-inner{
  height: fit-content;
}
.card-front{
  /* max-height: 300px !important; */
}
  .card-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card_newOrder {
  height:80%;
  perspective: 600px;
}
  .card-back {
    /* height: 250px; */
    padding: 10px 4px;
}
  .card-back h2 {
      font-size: 18px;
      margin-bottom: 15px;
  }
  
  .card-back li {
      font-size: 10px;
  }
  #copyButton2 {
    padding: 8px 12px;
    font-size: 14px;
}
  
  .card-back li span {
      font-size: 10px;
  }
    /* Override Bootstrap's default column padding */
    .no-gutter > [class*='col-'] {
      padding-left: 0;
      padding-right: 0;
  }
  
  /* If you still want some space between columns, adjust this value */
  .small-gutter > [class*='col-'] {
      padding-left: 5px;
      padding-right: 5px;
  }
}

/* Custom Stylesheet or in a <style> block */

/* Default styles for mobile */
@media (max-width: 767px) {
  .mobile-row-adjustment {
      margin: 0.5rem !important; /* Half of m-3 for mobile view */
  }
  .mobile-col-adjustment {
      padding-left: 0.2rem !important; /* Half of the default Bootstrap column gap */
      padding-right: 0.2rem !important; /* Half of the default Bootstrap column gap */
  }
}

/* Styles for desktop and up */
@media (min-width: 768px) {
  .mobile-row-adjustment {
      margin: 1rem !important;
  }
  .mobile-col-adjustment {
      padding-left: 0.5rem !important; /* Default Bootstrap column gap */
      padding-top: 0.5rem !important; /* Default Bootstrap column gap */
      padding-right: 0.5rem !important; /* Default Bootstrap column gap */
  }
}


@media screen and (max-width: 700px){
  .cardTitle{
    font-size: 18px !important;
  }
  .font10px{
    font-size: 15px;
  
  }
  .font12px{
    font-size: 10px;
  
  }
  .viewOrderPaymentStatus{
    margin-left:0px;
  }
  .paymentStatus{
    margin-top: 0px;
  }
  .textCenter_verticAndHorizontal {
    display: block; /* Reverts display back to the default for <div> */
    align-items: normal;
    justify-content: normal;
    text-align: left; /* Or 'inherit' or 'start', depending on what you need */
    /* Remove the height property if it's not required for mobile view */
  }
  .notifyDiv{
    text-align: center;
    margin-right: 70px;
  }
  .notification-badge-individual {
    font-size: 10px;
  }
  .notification-badge {
   
    right: 0px;
    left: 60px;
  }
  .block-mobile{
    display: block !important;
  }
/* Size Table */
.size_table{
  /* margin: 15px; */
  margin: auto;
  width:95.5%;
}
.headerName{margin-left:0px
  
}

.tg-0lax_title{
  font-weight: bolder;
  font-size: 20px;
  background-color: #d8d8d8;
}

.tg-0lax{
  border: 2px black solid;
  padding: 0px 0px;
}
.timeStampDesignMobile{
  font-size: 10px !important;
}
#root .ql-snow.ql-toolbar {
  display: block;
  width: 100%;
  height: 8rem;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.p-4 {
  padding: 0px !important;
}
 
}


.custom-file-label-mobile {
  display: inline-block;
  padding: 0.5em 1em;
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}
/* .custom-file-label-mobile{
  width: 100%;
} */
/* Assuming the breakpoint for mobile view is 768px */
/* @media (min-width: 769px) {
  .custom-file-label-mobile::before {

      content: "Choose File";
  }

 
}
.custom-file-label-mobile::before {

  content: "";
} */

.custom-file-input-wrapper .custom-file-input-label {
  display: inline-block;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f8f8f8;
  transition: background-color 0.3s;
}

.custom-file-input-wrapper .custom-file-input-label:hover {
  background-color: #e0e0e0;
}

/* tab  */


.tab-container {
  width: 100%;
  margin: auto;
  /* display: flex !important; */
}

.tab-buttons {
  /* display: flex !important; */
  flex-direction: column;
  padding-right: 20px;
  margin-left: 30px;

}

.tab-buttons button {
  background-color: #f1f1f1;
  color: black;
  margin-left: 5px;
  padding:10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.tab-buttons button:hover,
.tab-buttons button.active {
  background-color:  #012652;
  color:white;
}

.tab-content {
  /* border-left: 1px solid #ccc; */
  padding: 10px;
  margin-left: 30px;
}

.tab-content > div {
  /* display: none; */
}

.tab-content > div > h3 {
  margin-top: 0;
}


