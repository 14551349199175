
.mainProduct {
margin-top: 50px;
margin-bottom: 50px;
}

.productMainImg img {
width: 100%;
}

.productMoreImg {
margin-top: 25px;
}

.productMoreImg img {
width: 100%;
cursor: pointer;
}

@media (max-width: 767px) {
.productMoreImg img {
width: 100%;
}
}

.productInformation {
padding-left: 100px;
}
/* Override the Bootstrap table-responsive class */


@media (max-width: 767px) {
.productInformation {
padding-left: 0 !important;
}
.self-product-description .scrollable-table {
    overflow-x: scroll; /* Enables horizontal scrolling */
}
.self-product-description table {
    background: #CFE2FF;
    font-size: 13px;
    border-collapse: collapse; /* Added for better table structure */
    width: 100% !important;
}
.marginTop10_mobile{
    margin-top: 30px !important;
}
.self-product-description th, td {
    padding: 10px !important;
    border-style: none solid solid none;
}


}

.productTitle h2 {
color: #012652;
font-weight: bold;
position: relative;
}

.productTitle h2::before {
content: "";
display: block;
width: 67%;
height: 3px;
background: #FE2202;
left: 0;
top: 125%;
position: absolute;
}

.productColorSizeRow1 {
margin-top: 50px;
}

.productSize {
display: inline-block;
text-align: center;
width: 19%;
}

.productSize h6 {
font-size: 18px;
color: #000000;
}

.productSize input {
width: 50px;
text-align: center;
border-radius: 3px;
border-color: aliceblue;
font-weight: bold;
}

.productPrintSizeAndFiles {
margin-top: 15px;
}

.fileUploadTitle {
margin-top: 10px;
}

.productPrice {
margin-top: 50px;
}

.productPrice h6 {
font-size: 35px;
font-weight: 500;
color: #FE2202;
display: inline-block;
}

.productPrice p {
display: inline-block;
margin-left: 15px;
}

.productButton {
margin-top: 40px;
}

.productButton button {
border: 1px solid #fe2202;
border-radius: 50px;
background-color: #fe2202;
color: #ffffff;
display: inline-block;
font-family: Nunito,sans-serif;
font-size: 16px;
font-weight: 400;
font-weight: 600;
margin-right: 15px;
padding: 10px 30px;
transition: none;
transition: .3s;
}

.productButton button:hover {
background: transparent;
color: #fe2202;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

#neonShadow{
    height:50px;
    width:100px;
    border:none;
    border-radius:50px;
    transition:0.3s;
    background-color:rgba(156, 161, 160,0.3);
    animation: glow 1s infinite ;
    transition:0.5s;
  }
  #neonShadow span{
   
      display: block;
      width: 100%;
      height: 100%;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;
      text-align: center;
      /* padding-top: 15%;
      padding-right: 2.5%; */
      /* margin-right: 0px; */
      font-size: 1.2rem;
      transition: 0.3s;
      /* opacity: 0; */
      }
  span:hover{
      transition: 0.3s;
      opacity: 1;
      font-weight: 700;
      }
  
      .self-product-description {
        margin-top: 50px;
    }
    
    .self-product-description .scrollable-table {
        overflow-x: auto; /* Enables horizontal scrolling */
    }
    
    .self-product-description table {
        background: #CFE2FF;
        font-size: 13px;
        border-collapse: collapse; /* Added for better table structure */
     
    }
    
    .self-product-description table, th, td {
        border: 3px solid #ffffff !important;
    }
    
    .self-product-description th, td {
        padding: 10px 85px 10px 20px;
        border-style: none solid solid none;
    }
    
  

    .popup-content {
        position: absolute;
   color: white;
      overflow: scroll;
   bottom: 60%;
   height:"300px";
     width: auto;
        background-color: rgb(5, 5, 48);
        border: 1px solid #ccc;
        padding: 10px;
        z-index: 1;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }